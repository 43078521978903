<template>
  <div class="pt-6 ">
    <v-sheet  rounded  shaped outlined class="pa-6 mb-10" >
      <v-row>
        <v-col cols="4">
          <v-sheet>
            <imago ratio="1.2"  :src="currentTemplateThumb">
              <div style="background-color: #000000bb;position:absolute; bottom: 0; width: 100%;  font-family:sans-serif"
                   class="pa-3 caption font-weight-medium d-flex justify-end"
              >
                <v-btn small dark text rounded class="mr-3" :to="`/${$store.state.business.selectedBusiness.alias}/edit`">Редагувати</v-btn>
              </div>
            </imago>
          </v-sheet>
        </v-col>
        <v-col cols="8">
          <h4 class="text-h5 font-weight-thin">Встановлений шаблон: </h4>
          <h4 class="text-h4 font-weight-thin ">{{ currentTemplate.name }}</h4>
          <h4 class="mt-4">Кольорова схема: <span> РАДУГА </span></h4>
          <h4 class="mt-4">Модуль : <span> Ресторан </span></h4>
          <v-btn  outlined rounded color="blue darken-2" class="mt-16">Перейти</v-btn>

        </v-col>
      </v-row>
    </v-sheet>



    <div class="mt-5 mb-10">
      <div class="d-flex">
        <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
        <h4 class="text-h5 font-weight-medium mb-3">Avalon Group:</h4>
      </div>

      <v-row class="mt-3">
        <v-col cols="4" v-for="(template,i) in templates" :key="i">
          <v-sheet rounded  shaped outlined class="pa-6">
            <h4 class="text-h5 font-weight-light text-center mb-3">{{  template.name  }}</h4>
            <imago ratio="1.2"  :src="`http://localhost:8000/images/templates/${template.alias}/main.png`">
              <div style="background-color: #000000bb;position:absolute; bottom: 0; width: 100%;  font-family:sans-serif"
                   class="pa-3 caption font-weight-medium d-flex justify-end">
                <v-btn small dark rounded text     class="mr-3">Переглянути</v-btn>
                <v-btn small dark rounded outlined class="">Встановити</v-btn>
              </div>
            </imago>

          </v-sheet>
        </v-col>
      </v-row>
      <v-divider class="mb-10 mt-6"></v-divider>
    </div>


  </div>




</template>

<script>
import imago from "@/components/UI/imago";
import {mapActions} from "vuex";
export default {
  name: "DashBusinessDesign",
  components : {
    imago
  },
  data()  {
    return {
      currentTemplateThumb : '',
      currentTemplate : {},
      templates : [],

    }
  },
  methods : {
    ...mapActions('templates',  [ 'GET_USER_TEMPLATE' ]),
  },
  mounted(){
    this.loading = true

    this.GET_USER_TEMPLATE(this.$store.state.business.selectedBusiness.alias).then((out)=>{

      console.log(out)
      this.loading         = false
      this.currentTemplate = out.data.businessTemplate
      this.templates       = out.data.templates

      this.currentTemplateThumb = `http://localhost:8000/images/templates/${out.data.businessTemplate.alias}/main.png`

    }).catch(()=>{
      this.notify('Something went wrong')
      this.loading = false
    })


  }
}
</script>

<style scoped>

</style>